/// <reference path="o365.pwa.declaration.sw.FileCrudHandler.d.ts" />

import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';

import type * as FileCrudHandlerModule from 'o365.pwa.declaration.sw.FileCrudHandler.d.ts';

import type { FileStoreRecord, IFileStoreRecordOptions } from 'o365.pwa.declaration.shared.dexie.objectStores.FileStoreFile.d.ts';

declare var self: IO365ServiceWorkerGlobalScope;

(() => {
    const { IndexedDBHandler } = self.o365.importScripts<typeof import('o365.pwa.declaration.shared.IndexedDBHandler.d.ts')>("o365.pwa.modules.sw.IndexedDBHandler.ts");

    /**
    * TODO: Replace viewName with appId, databaseId, objectStoreId w/overrides 
    * Route: api/file/view/{viewName}/{PrimKey:Guid}/{FileName?}
    * Method: GET
    */
    async function handleView(options: { FileRef: string; appId: string }): Promise<FileStoreRecord | null> {
        try {
            if (!options.FileRef) {
                return null;
            }
            const record = await IndexedDBHandler.retrieveFileStoreRecord(options.FileRef, options.appId);
            return record ?? null;
        } catch (error) {
            throw error;
        }
    }

    /**
    * Route: api/file/download/{viewName}/{PrimKey:Guid}/{FileName?}
    * Method: GET
    */
    async function handleDownload(options: { FileRef: string; appId: string }): Promise<FileStoreRecord | null> {
        try {
            const record = await IndexedDBHandler.retrieveFileStoreRecord(options.FileRef, options.appId);
            return record ?? null;
        } catch (error) {
            throw error;
        }
    }

    /**
    * Route: api/file/upload/{viewName}/{PrimKey:Guid?}
    * Method: POST
    * Body: Form
    *     [key: string]: unkown // ColumnName: ColumnValue
    *     File sent in form
    *  
    */
    async function handleUpload(options?: {
        PrimKey?: string;
        Data: ArrayBuffer | Blob;
        MimeType: string;
        FileName: string;
        FileSize: number;
        Extension: string;
        PdfRef?: string;
        ThumbnailRef?: string;
        OriginalRef?: string;
        appID: string;
    }, primKey?: string, appID?: string): Promise<FileStoreRecord | null> {
        try {
            if (primKey && appID) {
                await IndexedDBHandler.createFileStoreRecord({
                    appID: appID,
                    primKey: primKey,
                    data: new ArrayBuffer(0),
                    chunks: [],
                    mimeType: '',
                    filename: '',
                    filesize: 0,
                    extension: '',
                    fileCrc: null,
                });

                const record = await IndexedDBHandler.retrieveFileStoreRecord(primKey, appID);

                return record ?? null;
            }
            const newFileRef = options?.PrimKey ?? self.crypto.randomUUID();
            //New FileStoreRecord
            const newFileStoreRecord = {
                primKey: newFileRef,
                mimeType: options!.MimeType,
                data: options!.Data,
                filesize: options!.FileSize,
                filename: options!.FileName,
                extension: options!.Extension,
                pdfRef: options!.PdfRef,
                thumbnailRef: options!.ThumbnailRef,
                originalRef: options!.OriginalRef,
                appID: options!.appID,
                fileCrc: null
            }
            await IndexedDBHandler.createFileStoreRecord(newFileStoreRecord);
            const record = await IndexedDBHandler.retrieveFileStoreRecord(newFileRef, appID ?? "");

            return record ?? null;
        } catch (error) {
            throw error;
        }
    }

    /**
     * Route: api/file/chunkupload/{uploadRef:Guid?}
     * Method: POST
     * Body: Form
     */
    async function handleChunkUpload(options: FileCrudHandlerModule.IChunkUploadOptions, headers: Map<string, Set<string>>): Promise<FileCrudHandlerModule.IChunkUploadResponse> {
        try {
            // TODO: FIX PROGRESS IN FRONT END
            let uploadRef: string | undefined = options.uploadRef;

            if (uploadRef === undefined) {
                throw new Error('UploadRef missing')
            }

            const response = <FileCrudHandlerModule.IChunkUploadResponse>{
                uploadRef: uploadRef,
                fileRef: <null | string>null,
                action: "",
                chunks: <Array<{
                    ChunkCRC: number | null;
                    ChunkGuid: string;
                    ChunkPosition: number;
                    ChunkSize: number;
                    Chunk_ID: number;
                }>>[],
                row: [],
            };

            const headerValues = {
                CRC: FileCrudHandler.getHeaderValues("x-file-crc", headers),
                CCR: FileCrudHandler.getContentRange(FileCrudHandler.getHeaderValues("custom-content-range", headers) as string | null),
            }

            if (headerValues.CCR === null) {
                throw new Error("Custom-Content range is null");
            }

            const thisChunk = {
                ChunkCRC: headerValues.CRC as number ?? null,
                ChunkGuid: self.crypto.randomUUID(),
                ChunkPosition: headerValues.CCR.start,
                ChunkSize: options.data.size,
                Chunk_ID: Math.floor(Math.random() * 135001) + 15000,
            };

            let record = await FileCrudHandler.handleView({ FileRef: uploadRef, appId: options.appID });

            if (record && record.filename == undefined && record.mimeType == undefined && record.extension == undefined) {
                await FileCrudHandler.handleFileUpdate(record, {
                    primKey: uploadRef,
                    data: options.data,
                    mimeType: options.mimeType,
                    filename: options.filename,
                    filesize: options.data.size,
                    extension: options.extension,
                    pdfRef: options?.extraValues.pdfRef as string ?? undefined,
                    thumbnailRef: options?.extraValues.thumbnailRef as string ?? undefined,
                    chunks: [...(record.chunks ?? []), thisChunk] ?? null,
                    appID: options.appID,
                    fileCrc: null,
                }, options.appID)
            };

            record = await FileCrudHandler.handleView({ FileRef: uploadRef, appId: options.appID });

            if (record === null) {
                throw new Error('No record found');
            }

            if (record?.primKey !== uploadRef) {
                response.action = "StartUpload"
                response.chunks.push(thisChunk);
                response.fileRef = record!.primKey;
                response.uploadRef = record!.primKey;

                await IndexedDBHandler.createFileStoreRecord({
                    appID: record.appID,
                    data: record.data,
                    extension: record.extension,
                    fileCrc: record.CRC,
                    filename: record.filename,
                    filesize: record.filesize,
                    mimeType: record.mimeType,
                    primKey: record.primKey,
                    chunks: record.chunks,
                    originalRef: record.originalRef ?? undefined,
                    pdfRef: record.pdfRef ?? undefined,
                    thumbnailRef: record.thumbnailRef ?? undefined,
                });
            } else {
                if (headerValues.CCR.end === headerValues.CCR.total - 1) {
                    response.fileRef = record.primKey;
                }

                const combineChunks = [...(record.chunks ?? []), thisChunk];
                const combinedChunk = await FileCrudHandler.combineBlobs(record.dataAsBlob, options.data);

                response.action = "ResumeUpload"

                record.data = await combinedChunk.arrayBuffer();
                record.filesize = combinedChunk.size;

                response.chunks = combineChunks;

                record.chunks = combineChunks;

                await IndexedDBHandler.updateFileStoreRecord(record);
            }

            response.row.push({ PrimKey: record.primKey });

            return response;
        } catch (error) {
            throw error;
        }
    }

    async function handleChunkSetCRC(options: {
        FileCRC: number;
        FileName: string;
        FileSize: number;
        UploadRef: string;
        ViewName: string;
        appId: string;
    }): Promise<{ action: string; fileRef?: string; }> {
        try {
            const record = await FileCrudHandler.handleView({ FileRef: options.UploadRef, appId: options.appId });

            if (!record) {
                throw new Error("No record found");
            }

            await FileCrudHandler.handleFileUpdate(
                record,
                {
                    fileCrc: options.FileCRC
                },
                options.appId);
            return { action: "ResumeUpload" }
        } catch (error) {
            throw error;
        }
    }
    /**
    * Route: api/view-pdf/{viewName}/{PrimKey:Guid}/{FileName?}
    * Method: GET
    */
    async function handlePdfView(options: { FileRef: string; appId: string; }): Promise<FileStoreRecord | null> {
        try {
            const record = await IndexedDBHandler.retrieveFileStoreRecord(options.FileRef, options.appId);

            if (record?.extension === "pdf") return record;
            const pdfRef = record?.pdfRef ?? null;
            if (pdfRef === null) return null;

            const pdfChildRecord = await IndexedDBHandler.retrieveFileStoreRecord(pdfRef, options.appId);

            return pdfChildRecord;
        } catch (error) {
            throw error;
        }
    }

    /**
    * Route: api/download-pdf/{viewName}/{PrimKey:Guid}/{FileName?}
    * Method: GET
    */
    async function handlePdfDownload(options: { FileRef: string; appId: string }): Promise<FileStoreRecord | null> {
        try {
            const record = await IndexedDBHandler.retrieveFileStoreRecord(options.FileRef, options.appId);

            if (record?.extension === "pdf") return record;
            const pdfRef = record?.pdfRef ?? null;
            if (pdfRef === null) return null;

            const pdfChildRecord = await IndexedDBHandler.retrieveFileStoreRecord(record?.pdfRef!, options.appId);

            return pdfChildRecord ?? null;
        } catch (error) {
            throw error;
        }
    }

    async function handleFileUpdate(OriginalRecord: FileStoreRecord, NewValues: IFileStoreRecordOptions, appId: string): Promise<FileStoreRecord | null> {
        try {
            if (Object.keys(NewValues).length < 1) throw new Error("No update values provided.");

            const newRecord = Object.assign(OriginalRecord, { ...NewValues });

            await IndexedDBHandler.updateFileStoreRecord(newRecord);

            const getRecord = await FileCrudHandler.handleView({ FileRef: newRecord.primKey, appId: appId });

            return getRecord;
        } catch (error) {
            throw error;
        }
    }

    async function combineBlobs(blob1: Blob | ArrayBuffer, blob2: Blob): Promise<Blob> {
        return new Promise(async (resolve, reject) => {
            try {
                let arrayBuffer1: ArrayBuffer;

                if (blob1 instanceof Blob) {
                    arrayBuffer1 = await FileCrudHandler.blobToArrayBuffer(blob1);
                } else if (blob1 instanceof ArrayBuffer) {
                    arrayBuffer1 = blob1;
                } else {
                    reject("Invalid input for blob1. It should be a Blob or ArrayBuffer.");
                    return;
                }

                const arrayBuffer2 = await FileCrudHandler.blobToArrayBuffer(blob2);

                const combinedArrayBuffer = new Uint8Array(arrayBuffer1.byteLength + arrayBuffer2.byteLength);
                combinedArrayBuffer.set(new Uint8Array(arrayBuffer1), 0);
                combinedArrayBuffer.set(new Uint8Array(arrayBuffer2), arrayBuffer1.byteLength);

                const combinedBlob = new Blob([combinedArrayBuffer], { type: blob2.type });

                resolve(combinedBlob);
            } catch (error) {
                reject(error);
            }
        });
    }

    async function blobToArrayBuffer(blob: Blob): Promise<ArrayBuffer> {
        const readerPromise = new Promise<ArrayBuffer>((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = function () {
                if (reader.result === null) {
                    reject("Error reading Blob as ArrayBuffer: FileReader result is null.");
                } else {
                    resolve(reader.result as ArrayBuffer);
                }
            };

            reader.onerror = function () {
                reject("Error reading Blob as ArrayBuffer.");
            };

            reader.readAsArrayBuffer(blob);
        });

        return await readerPromise;
    }

    function getHeaderValues(key: string, headers: Map<string, Set<string | number>>) {
        const valuesSet = headers.get(key);

        if (valuesSet) {
            // If the key exists in the map, valuesSet will contain the Set<string>
            return [...valuesSet][0];
        } else {
            // Handle the case where the key doesn't exist in the map
            // self.o365.logger.log(`Key "${key}" not found in the map.`);
            return null;
        }
    }

    function getContentRange(contentString: string | null): FileCrudHandlerModule.IHTTPCONTENTRANGE | null {
        if (!contentString) return null;
        // Regular expression pattern to match and extract values
        const pattern = /(\w+) (\d+)-(\d+)\/(\d+)/;

        // Use the regular expression to extract values
        const matches = contentString.match(pattern);

        if (matches && matches.length === 5) {
            const unit = matches[1];
            const start = parseInt(matches[2]);
            const end = parseInt(matches[3]);
            const total = parseInt(matches[4]);

            return {
                unit,
                start,
                end,
                total
            }
        } else {
            self.o365.logger.error("Invalid Content-Range header format");
            return null;
        }
    }

    const FileCrudHandler = <FileCrudHandlerModule.FileCrudHandler>{
        handleView,
        handleDownload,
        handleUpload,
        handleChunkUpload,
        handleChunkSetCRC,
        handlePdfView,
        handlePdfDownload,
        handleFileUpdate,
        combineBlobs,
        blobToArrayBuffer,
        getHeaderValues,
        getContentRange
    };

    self.o365.exportScripts<typeof import('o365.pwa.declaration.sw.FileCrudHandler.d.ts')>({ FileCrudHandler });
})();